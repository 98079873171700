.service {
    color: white;
    background-color: black;
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
}

.how-it-works {
    text-align: center;
    padding: 40px;
    vertical-align: middle;
}