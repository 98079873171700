.ecsTitle {
    font-size: xx-large;
}

.whoWeare {
    font-size: x-large;
}


/* CSS */
.button-28 {
    appearance: none;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    font-size: 12px;
    line-height: normal;
    margin-top: 2%;
    min-height: 29%;
    min-width: 29%;
    outline: none;
    padding: 10px 12px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 34%;
    will-change: transform;
    float: left;
}

.button-28:disabled {
    pointer-events: none;
}

.button-28:hover {
    color: white;
    border-color: gray;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

}

.button-28:active {
    box-shadow: none;
    transform: translateY(0);
}

* {
    box-sizing: border-box;
}


.mySlides {
    display: none;
}

img {
    vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
}

/* Caption text */
.text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: left;
    float: left;
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* The dots/bullets/indicators */
.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    float: left;
}


.active {
    background-color: #717171;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 6s;
}

/*
@keyframes fade {
    from {
        opacity: 1
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px
    }
}
*/