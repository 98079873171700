#pdf {
    text-align: center;
}

#pdf canvas {
    border: 1px solid rgb(200, 200, 200);
    width: 100%;
}

#monthly-invoice {
    text-align: center;
}

#monthly-invoice canvas {
    border: 1px solid rgb(200, 200, 200);
    width: 100%;
}